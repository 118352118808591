<template>
  <ContainerBasic container-type="PAGE">
    <Headline
      v-if="blockData.headline"
      :has-decoration="true"
      :headline="blockData.headline"
      :level="blockData.headlineSize"
      class="pt-md md:pt-xl"
    />

    <div
      class="flex flex-col justify-center gap-sm md:gap-md touch:md:flex-row lg:flex-row mt-md md:mt-xl"
    >
      <div
        v-for="item of blockData.items"
        :key="item.id"
        class="relative z-10 flex flex-col overflow-hidden after:border-activesection-alpha before:border-b-activesection-alpha"
        :class="[
          getItemFlexBase(blockData.items.length),
          item.bgColor ? 'md:p-md p-sm' : 'px-md md:px-0',
        ]"
        :style="[item.bgColor]"
      >
        <atom-circle-icon
          :icon="item.icon"
          class="mb-sm md:mb-md"
        ></atom-circle-icon>
        <div
          v-if="item.headline"
          class="!mb-sm sm:!mb-sm md:!mb-md min-h-[32px] flex items-end mx-auto w-fit"
        >
          <span
            :style="item.textColor"
            class="mx-auto text-center h4 text-inherit"
          >
            {{ item.headline }}
          </span>
        </div>
        <WYSIWYGContentRaw
          :no-container-padding="true"
          :style="item.textColor"
          :content="item.text"
        ></WYSIWYGContentRaw>
        <div v-if="item.cta" class="mt-auto pt-sm">
          <CallToAction :block-data="item.cta"></CallToAction>
        </div>
      </div>
    </div>
  </ContainerBasic>
</template>
<script lang="ts" setup>
import ContainerBasic from '@/components/components/container-basic.vue';
import AtomCircleIcon from '@/components/atom/circle-icon.vue';
import CallToAction from '~~/src/components/block/cta/vdv/CallToAction.vue';
import { Headline, WYSIWYGContentRaw } from '~/complib';
import type { CircleTextData } from '../blockTypes';

defineProps({
  classes: {
    type: String,
    required: false,
    default: '',
  },
  blockData: {
    type: Object as PropType<CircleTextData>,
    required: true,
    default: {} as CircleTextData,
  },
});

function getItemFlexBase(items: number) {
  switch (items) {
    case 1:
      return 'basis-full';
    case 2:
      return 'basis-1/2';
    case 3:
      return 'basis-1/3';
    case 4:
      return 'basis-1/4';
  }
}
</script>
